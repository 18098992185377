import classNames from "~/lib/utils/classNames";

export default function Label({
  className,
  ...props
}: JSX.IntrinsicElements["label"]) {
  return (
    <label
      className={classNames(
        "block font-medium dark:text-white",
        className,
        !className && "text-gray-500"
      )}
      {...props}
    />
  );
}
